var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full grid grid-cols-2 gap-6" },
    _vm._l(_vm.movies, function (movie, movieIndex) {
      return _c("MovieManageItem", {
        key: movieIndex,
        attrs: {
          featuredLists: _vm.featuredLists,
          hasMoreList: _vm.featuredLists.length < _vm.featuredListsTotal,
        },
        on: {
          onDelete: function ($event) {
            return _vm.deletedMovie(movieIndex)
          },
          onLoadFeaturedLists: function ($event) {
            return _vm.loadFeaturedList()
          },
        },
        model: {
          value: _vm.movies[movieIndex],
          callback: function ($$v) {
            _vm.$set(_vm.movies, movieIndex, $$v)
          },
          expression: "movies[movieIndex]",
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
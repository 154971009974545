var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full flex justify-start" }, [
    _c("div", { staticClass: "switchArea align-middle" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.toggleValue,
            expression: "toggleValue",
          },
        ],
        attrs: { type: "checkbox", id: _vm.labelIdFor },
        domProps: {
          checked: Array.isArray(_vm.toggleValue)
            ? _vm._i(_vm.toggleValue, null) > -1
            : _vm.toggleValue,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.toggleValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.toggleValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.toggleValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.toggleValue = $$c
            }
          },
        },
      }),
      _c("label", { attrs: { for: _vm.labelIdFor } }, [_c("span")]),
      _c("div", { attrs: { id: "swImg" } }),
    ]),
    _vm.toggleValue
      ? _c("span", { staticClass: "text-xxs pt-1 ml-1 font-bold" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-span-1" },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-6 gap-x-6 hover:bg-gray-200 cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.openMovieDetail()
            },
          },
        },
        [
          _c("FlMovieImage", {
            staticClass: "col-span-2",
            attrs: { value: _vm.movieImage },
          }),
          _c(
            "div",
            { staticClass: "col-span-4 grid grid-cols-4 justify-between" },
            [
              _c("div", { staticClass: "col-span-3 text-left" }, [
                _c("h3", { staticClass: "text-base overflow-hidden" }, [
                  _vm._v(" " + _vm._s(_vm.value.film_name) + " "),
                  _vm.value.status === _vm.statusValue.request_fix
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "bg-red-500 rounded-full p-1 text-white font-bold text-xxs",
                        },
                        [_vm._v(" 修正依頼 ")]
                      )
                    : _vm._e(),
                ]),
                _c("p", { staticClass: "text-xs" }, [
                  _vm._v(_vm._s(_vm.value.director)),
                ]),
              ]),
              _c("div", { staticClass: "col-span-1 text-right" }, [
                _vm.loginUserRole == "manager" ||
                _vm.loginUserRole == "provider"
                  ? _c(
                      "button",
                      {
                        attrs: { alt: "削除" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$modal.show(
                              "checkDelete-" + _vm.value.uuid
                            )
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass:
                            "px-5 lg:px-3 md:px-2 py-1 mb-8 lg:mb-4 md:mb-4 bg-very-light-grey",
                          attrs: {
                            src: require("@/assets/image/trash_icon.svg"),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "col-span-4 flex justify-between" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "flex-grow py-1 font-bold bg-light-yellow text-sm lg:text-xs md:text-xs",
                      class: _vm.loginUserRole === "manager" ? "mr-2" : "",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.openMovieEdit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.administrators.index.edit")) +
                          " "
                      ),
                    ]
                  ),
                  _vm.loginUserRole === "manager" &&
                  _vm.value.status === _vm.statusValue.approved
                    ? _c("div", { staticClass: "flex-grow relative mr-2" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full h-full relative py-1 font-bold bg-light-yellow text-sm lg:text-xs md:text-xs disabled:bg-brown-grey",
                            attrs: {
                              disabled:
                                !_vm.featuredLists.length && !_vm.hasMoreList,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.openList = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.administrators.index.add")
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.openList
                          ? _c("div", {
                              staticClass:
                                "fixed inset-0 h-screen w-screen bg-gray-200 z-40",
                              staticStyle: { opacity: "0.05" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  _vm.openList = false
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.openList
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "origin-top-right absolute left-0 bg-white mt-1 shadow-lg z-50",
                              },
                              [
                                _c("div", {}),
                                _vm._l(
                                  _vm.featuredLists,
                                  function (list, index) {
                                    return _c(
                                      "button",
                                      {
                                        key: index,
                                        staticClass:
                                          "block w-full text-left text-sm whitespace-no-wrap py-1 px-2 hover:bg-gray-200",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.addToList(list)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(list.list_name) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm.hasMoreList
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "block w-full text-center text-sm px-2 hover:bg-gray-200",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.$emit(
                                              "onLoadFeaturedLists"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" load more ")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.loginUserRole === "manager"
                    ? [
                        _vm.loginUserRole === "manager"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-grow py-1 mr-2 font-bold text-sm lg:text-xs md:text-xs bg-light-yellow disabled:bg-brown-grey disabled:cursor-not-allowed",
                                attrs: {
                                  disabled:
                                    _vm.value.status ===
                                    _vm.statusValue.approved,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.approveMovie()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.administrators.index.approve"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.loginUserRole === "manager"
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-grow py-1 mr-2 font-bold text-sm lg:text-xs md:text-xs bg-light-yellow disabled:bg-brown-grey disabled:cursor-not-allowed",
                                attrs: {
                                  disabled:
                                    _vm.value.status ===
                                    _vm.statusValue.declined,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.denyMovie()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.administrators.index.deny")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.loginUserRole === "manager" &&
                        _vm.value.status !== _vm.statusValue.approved
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-grow py-1 font-bold text-sm lg:text-xs md:text-xs bg-light-yellow",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$modal.show(
                                      "RequestFix-" + _vm.value.uuid
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.administrators.index.request_fix"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.value.status === _vm.statusValue.approved
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "flex-grow py-1 font-bold text-sm lg:text-xs md:text-xs",
                                class: _vm.value.is_available
                                  ? "bg-brown-grey"
                                  : "bg-light-yellow",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApprove(_vm.movie)
                                  },
                                },
                              },
                              [
                                !_vm.value.is_available
                                  ? _c("span", [_vm._v("検索対象にする")])
                                  : _c("span", [_vm._v("検索対象から外す")]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "successNoticeModal-" + _vm.value.uuid,
          title: _vm.noticeMessage,
          contents: _vm.noticeMessageDetail,
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "checkDelete-" + _vm.value.uuid,
          title: "映画の削除",
          contents: _vm.value.film_name + "を削除します。よろしいですか？",
          buttons: ["キャンセル", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.deleteMovie()
          },
        },
      }),
      _c(
        "NoticeModal",
        {
          attrs: {
            modalName: "RequestFix-" + _vm.value.uuid,
            buttons: ["キャンセル", "OK"],
          },
          on: {
            click: function ($event) {
              return _vm.requestFix(_vm.fixRequestItems, _vm.fixRequestMessage)
            },
          },
        },
        [
          _c("FlCheckboxGroup", {
            staticClass: "w-full",
            attrs: {
              options: _vm.$store.state.enums.fix_request_reason,
              translateText: false,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.fixRequestItems,
              callback: function ($$v) {
                _vm.fixRequestItems = $$v
              },
              expression: "fixRequestItems",
            },
          }),
          _c("FlTextArea", {
            staticClass: "w-full",
            attrs: { label: "自由文章", rules: { max: 4000 } },
            model: {
              value: _vm.fixRequestMessage,
              callback: function ($$v) {
                _vm.fixRequestMessage = $$v
              },
              expression: "fixRequestMessage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dark_overlay
        ? _c("div", { staticClass: "over-lay", on: { click: _vm.maskClose } })
        : _vm._e(),
      _c("vue-tags-input", {
        staticClass: "movie-language-field",
        class: { "z-20": _vm.dark_overlay },
        attrs: {
          placeholder: "",
          tags: _vm.getSelectedMovieLanguage(),
          "autocomplete-min-length": 0,
          "add-only-from-autocomplete": true,
          "autocomplete-items": _vm.languageTagList,
          "avoid-adding-duplicates": true,
        },
        on: {
          "tags-changed": _vm.languageSelectionChanged,
          focus: function ($event) {
            return _vm.maskOpen()
          },
        },
        model: {
          value: _vm.tagPlaceholder,
          callback: function ($$v) {
            _vm.tagPlaceholder = $$v
          },
          expression: "tagPlaceholder",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlInputWrapper", {
    attrs: { label: _vm.label, rules: _vm.computedRule, name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return _vm._l(_vm.options, function (item, key) {
            return _c(
              "div",
              {
                key: key,
                class:
                  _vm.direction === "horizontal"
                    ? "inline-block"
                    : "list-item list-none",
              },
              [
                _c(
                  "FlCheckbox",
                  {
                    class: [
                      errors.length ? "bg-red-200 border-red-600" : "",
                      _vm.direction === "horizontal" ? "mr-4" : "",
                    ],
                    attrs: {
                      trueValue: item.value,
                      disabled: _vm.disabled,
                      name: _vm.name,
                      inputKey: _vm.inputKey,
                      compareValues: _vm.compareValues,
                    },
                    model: {
                      value: _vm.mutableValue,
                      callback: function ($$v) {
                        _vm.mutableValue = $$v
                      },
                      expression: "mutableValue",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.translateText ? _vm.$t(item.text) : item.text
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            )
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
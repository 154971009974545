var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen" }, [
    _c(
      "div",
      { staticClass: "container mx-auto pt-4 mb-20" },
      [
        _vm.loginUserRole === "consumer"
          ? _c("h2", { staticClass: "text-2xl font-normal py-2" }, [
              _vm._v("Recommended films list"),
            ])
          : _vm._e(),
        _vm.loginUserRole === "consumer"
          ? _c(
              "div",
              { staticClass: "grid grid-cols-12 gap-6 mb-16" },
              _vm._l(_vm.advertisements, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass:
                      "col-span-4 rounded-lg overflow-hidden relative shadow-md hover:shadow-lg aspect-w-16 aspect-h-9",
                    attrs: { href: item.url, target: "_blank" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-full h-full flex cursor-pointer" },
                      [
                        _c("img", {
                          staticClass: "w-full h-full object-cover",
                          attrs: { src: item.image.src },
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "absolute bottom-2 left-2 text-white text-xl text-shadow font-semibold whitespace-pre-wrap",
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c("div", {
                          staticClass:
                            "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                        }),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.loginUserRole === "consumer"
          ? _c(
              "div",
              {
                staticClass:
                  "text-sm bg-gray-200 p-2 rounded-md w-1/2 mx-auto mb-4 text-justify",
              },
              [
                _c("i18n", {
                  attrs: {
                    path: "pages.favorits.custom_featured_list",
                    tag: "span",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "contact",
                        fn: function () {
                          return [
                            _c(
                              "a",
                              {
                                staticClass: "underline",
                                attrs: { href: "/contact" },
                              },
                              [_vm._v(_vm._s(_vm.$t("pages.favorits.contact")))]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    766157481
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchMovies.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
              _c(
                "label",
                {
                  staticClass:
                    "col-span-3 text-right md:text-sm align-bottom pt-2 tracking-wider",
                },
                [_vm._v(_vm._s(_vm.$t("pages.commons.index.genre")))]
              ),
              _c(
                "div",
                { staticClass: "col-span-9 h-10 max-h-full" },
                [
                  _c("InputGenreField", {
                    staticClass: "w-full",
                    model: {
                      value: _vm.searchCondition.genres,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchCondition, "genres", $$v)
                      },
                      expression: "searchCondition.genres",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 grid grid-cols-12 gap-x-6" }, [
              _c(
                "label",
                { staticClass: "col-span-3 text-right md:text-sm pt-2" },
                [_vm._v(_vm._s(_vm.$t("pages.commons.index.production_year")))]
              ),
              _c(
                "div",
                {
                  ref: "productionYears",
                  staticClass:
                    "col-span-9 grid grid-cols-8 gap-x-1 h-full relative bg-white border-brown-grey",
                },
                _vm._l(_vm.productionYears, function (productionYear, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "col-span-1 p-1 mb-1 rounded-full border-2 border-brown-grey bg-white text-brown-grey text-center text-xs",
                      on: {
                        click: function ($event) {
                          return _vm.checkProductionYears(key, $event)
                        },
                      },
                    },
                    [
                      _c("button", { staticClass: "inline-block mx-auto" }, [
                        _vm._v(_vm._s(productionYear)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-span-3 text-right md:text-sm tracking-wider",
                },
                [
                  _c("label", { staticClass: "inline-block" }, [
                    _vm._v(_vm._s(_vm.$t("pages.commons.index.awarded_only"))),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-span-9 text-left tracking-wider mr-4" },
                [
                  _c("InputToggle", {
                    attrs: { toggleId: "awarded_only", label: "Selected!!" },
                    model: {
                      value: _vm.searchCondition.awarded,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchCondition, "awarded", $$v)
                      },
                      expression: "searchCondition.awarded",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.displayed
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm align-bottom pt-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("pages.commons.index.tags")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-9 h-10 max-h-full" },
                    [
                      _c("InputTagsField", {
                        model: {
                          value: _vm.searchCondition.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchCondition, "tags", $$v)
                          },
                          expression: "searchCondition.tags",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.displayed,
                    expression: "displayed",
                  },
                ],
                staticClass: "grid grid-cols-12 gap-x-6 mb-4",
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "col-span-3 text-right md:text-sm align-bottom pt-2",
                  },
                  [_vm._v(_vm._s(_vm.$t("pages.commons.index.subtitles")))]
                ),
                _c(
                  "div",
                  { staticClass: "col-span-9 h-10 max-h-full" },
                  [
                    _c("InputSubtitleLanguageField", {
                      model: {
                        value: _vm.searchCondition.subtitles,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchCondition, "subtitles", $$v)
                        },
                        expression: "searchCondition.subtitles",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm.displayed
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm align-bottom pt-2",
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("pages.commons.index.director_name"))
                      ),
                    ]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchCondition.director,
                        expression: "searchCondition.director",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "input_text col-span-9 leading-tight",
                    attrs: { type: "text" },
                    domProps: { value: _vm.searchCondition.director },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.searchCondition,
                          "director",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.displayed
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm align-bottom pt-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("pages.commons.index.main_cast")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchCondition.main_cast,
                        expression: "searchCondition.main_cast",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "input_text col-span-9 leading-tight",
                    attrs: { type: "text" },
                    domProps: { value: _vm.searchCondition.main_cast },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.searchCondition,
                          "main_cast",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.displayed
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm tracking-wider",
                    },
                    [
                      _c("label", { staticClass: "inline-block md:text-sm" }, [
                        _vm._v(_vm._s(_vm.$t("pages.commons.index.length"))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-9 text-left flex items-center justify-between tracking-wider mr-4",
                    },
                    [
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { trueValue: _vm.formatValue.long },
                          model: {
                            value: _vm.searchCondition.formats,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchCondition, "formats", $$v)
                            },
                            expression: "searchCondition.formats",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("pages.commons.index.long")))]
                      ),
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { trueValue: _vm.formatValue.medium },
                          model: {
                            value: _vm.searchCondition.formats,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchCondition, "formats", $$v)
                            },
                            expression: "searchCondition.formats",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.commons.index.medium")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { trueValue: _vm.formatValue.short },
                          model: {
                            value: _vm.searchCondition.formats,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchCondition, "formats", $$v)
                            },
                            expression: "searchCondition.formats",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.commons.index.short")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "FlCheckbox",
                        {
                          attrs: {
                            trueValue: [
                              _vm.formatValue.short,
                              _vm.formatValue.medium,
                              _vm.formatValue.long,
                            ],
                            falseValue: [],
                          },
                          model: {
                            value: _vm.searchCondition.formats,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchCondition, "formats", $$v)
                            },
                            expression: "searchCondition.formats",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.commons.index.all")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-span-3 text-right md:text-sm tracking-wider",
                },
                [
                  _c("label", { staticClass: "inline-block" }, [
                    _vm._v(_vm._s(_vm.$t("pages.commons.index.film_type"))),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-span-9" },
                [
                  _c("FlRadioGroup", {
                    attrs: { direction: "horizontal", options: _vm.filmType },
                    model: {
                      value: _vm.searchCondition.film_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchCondition, "film_type", $$v)
                      },
                      expression: "searchCondition.film_type",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.loginUserRole === "manager"
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-6" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm tracking-wider",
                    },
                    [
                      _c("label", { staticClass: "inline-block" }, [
                        _vm._v(_vm._s(_vm.$t("pages.commons.index.status"))),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-span-9 text-left flex items-center justify-between tracking-wider",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 3,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.commons.index.published")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 1,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.commons.index.draft")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 2,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.commons.index.pending_review")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 4,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.commons.index.trashed")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 5,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.commons.index.declined")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-8" },
                        [
                          _c(
                            "FlRadio",
                            {
                              attrs: {
                                inputFieldName: "status",
                                fieldValue: 6,
                              },
                              model: {
                                value: _vm.searchCondition.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchCondition, "status", $$v)
                                },
                                expression: "searchCondition.status",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.commons.index.fix_requested")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.displayed
              ? _c(
                  "div",
                  { staticClass: "grid grid-cols-12 gap-x-6 mt-2 mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-span-3 text-right tracking-wider" },
                      [
                        _c(
                          "label",
                          { staticClass: "inline-block md:text-sm" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("pages.commons.index.dubbled_only"))
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-9 text-left tracking-wider mr-4",
                      },
                      [
                        _c("InputToggle", {
                          attrs: {
                            toggleId: "dubbled_only",
                            label: "Selected!",
                          },
                          model: {
                            value: _vm.searchCondition.translated,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchCondition, "translated", $$v)
                            },
                            expression: "searchCondition.translated",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.displayed
              ? _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "col-span-3 text-right md:text-sm align-bottom pt-2",
                    },
                    [_vm._v(_vm._s(_vm.$t("pages.commons.index.distributor")))]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.searchCondition.provider,
                        expression: "searchCondition.provider",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "input_text col-span-9 leading-tight",
                    attrs: { type: "text" },
                    domProps: { value: _vm.searchCondition.provider },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.searchCondition,
                          "provider",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mb-4" }, [
              _c(
                "label",
                {
                  staticClass:
                    "col-span-3 text-right md:text-sm align-bottom pt-2",
                },
                [_vm._v(_vm._s(_vm.$t("pages.commons.index.free_word")))]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchCondition.free_word,
                    expression: "searchCondition.free_word",
                    modifiers: { trim: true },
                  },
                ],
                staticClass:
                  "input_text col-span-9 leading-tight focus:outline-none",
                attrs: { type: "text" },
                domProps: { value: _vm.searchCondition.free_word },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.searchCondition,
                      "free_word",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _vm.loginUserRole !== "provider"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-span-9 col-start-4 rounded-md bg-gray-200 text-justify text-xs p-2 mt-2",
                    },
                    [
                      _c("p", [
                        _vm._v(
                          ' If your search yields zero results, try deleting words containing "apostrophe" or similar words and search search again. '
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Example: The FI's Are Expecting → Search for The Are Expecting"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 mt-4" }, [
              _c(
                "div",
                { staticClass: "col-start-4 col-span-6 mx-6 text-center" },
                [
                  _c("ChangeFieldButton", {
                    attrs: { opened: _vm.displayed },
                    on: { clickButton: _vm.changeButtonType },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-cols-12 gap-x-6 my-4" }, [
              _c(
                "div",
                { staticClass: "col-start-4 col-span-6 mx-6 bg-off-yellow" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: { classList: "py-3 px-2 text-xl" },
                      on: { clickButton: _vm.searchMovies },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("pages.commons.index.search_button"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm.loginUserRole !== "consumer"
              ? [
                  _vm.movies.length > 0
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-12 gap-x-6 mb-4" },
                        [
                          _vm.loginUserRole === "manager"
                            ? _c("div", {
                                staticClass:
                                  "col-start-10 col-span-3 flex items-center mb-4",
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-start-3 col-span-8 flex items-center",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                                  class: [
                                    _vm.activeSort.column === "approved_at"
                                      ? "text-black bg-very-light-grey"
                                      : "text-brown-grey bg-light-grey",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSort("approved_at")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.index.approved_date"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.activeSort.column === "approved_at"
                                    ? _c("img", {
                                        staticClass:
                                          "w-3 h-3 absolute sort-image",
                                        class: [
                                          _vm.activeSort.sort == "desc"
                                            ? "desc-icon"
                                            : "",
                                        ],
                                        attrs: {
                                          src: require("@/assets/image/sort_icon.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                                  class: [
                                    _vm.activeSort.column === "registered_at"
                                      ? "text-black bg-very-light-grey"
                                      : "text-brown-grey bg-light-grey",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSort("registered_at")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.index.registered_date"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.activeSort.column === "registered_at"
                                    ? _c("img", {
                                        staticClass:
                                          "w-3 h-3 absolute sort-image",
                                        class: [
                                          _vm.activeSort.sort == "desc"
                                            ? "desc-icon"
                                            : "",
                                        ],
                                        attrs: {
                                          src: require("@/assets/image/sort_icon.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                                  class: [
                                    _vm.activeSort.column == "title"
                                      ? "text-black bg-very-light-grey"
                                      : "text-brown-grey bg-light-grey",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSort("title")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("pages.commons.index.title")
                                      ) +
                                      " "
                                  ),
                                  _vm.activeSort.column == "title"
                                    ? _c("img", {
                                        staticClass:
                                          "w-3 h-3 absolute sort-image",
                                        class: [
                                          _vm.activeSort.sort == "desc"
                                            ? "desc-icon"
                                            : "",
                                        ],
                                        attrs: {
                                          src: require("@/assets/image/sort_icon.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "w-full px-4 py-2 mr-1 font-bold text-xs relative",
                                  class: [
                                    _vm.activeSort.column == "production_year"
                                      ? "text-black bg-very-light-grey"
                                      : "text-brown-grey bg-light-grey",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSort("production_year")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.index.production_year"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.activeSort.column == "production_year"
                                    ? _c("img", {
                                        staticClass:
                                          "w-3 h-3 absolute sort-image",
                                        class: [
                                          _vm.activeSort.sort == "desc"
                                            ? "desc-icon"
                                            : "",
                                        ],
                                        attrs: {
                                          src: require("@/assets/image/sort_icon.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 flex items-center" },
                            [
                              _c(
                                "p",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.commons.index.search_results"
                                        )
                                      ) +
                                      ": " +
                                      _vm._s(_vm.movieTotal) +
                                      " "
                                  ),
                                  _vm.$i18n.locale === "ja"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "pages.commons.index.result_count"
                                              )
                                            )
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { ref: "divRef" },
                    [
                      _c("MovieManageList", {
                        attrs: { movies: _vm.movies, meta: _vm.moviesMeta },
                        on: {
                          open: _vm.getMoreMovies,
                          update: _vm.updateMovieList,
                          autoPager: _vm.autoPager,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : [
                  _c(
                    "p",
                    { staticClass: "text-right" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.commons.index.search_results")) +
                          ": " +
                          _vm._s(_vm.movieTotal) +
                          " "
                      ),
                      _vm.$i18n.locale === "ja"
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.commons.index.result_count")
                                )
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { ref: "divRef", staticClass: "h-full pt-6 search-result" },
                    [
                      _c("MovieList", {
                        attrs: {
                          movies: _vm.movies,
                          meta: _vm.moviesMeta,
                          registerButton: false,
                          title: _vm.searchResultTitle,
                          showContentsCount: _vm.searchResultContentsCount,
                          titleFieldClass: "xl:w-5/12 lg:w-6/12 md:w-7/12",
                        },
                        on: {
                          open: _vm.getMoreMovies,
                          autoPager: _vm.autoPager,
                        },
                      }),
                    ],
                    1
                  ),
                ],
            _c("div", { ref: "RefScroll" }),
          ],
          2
        ),
        _c("NoticeModal", {
          attrs: {
            modalName: "announcementModal",
            contents:
              "上記の「作品追加」タブから、販売をご希望される作品情報を追加ください。ご登録の前に、まずは、こちらで登録方法などについて、ご確認ください。",
            buttons: ["Close", "OK"],
          },
          on: {
            click: function ($event) {
              return _vm.openHelpGuide()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
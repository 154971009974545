var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-3 gap-6 flex-initial items-stretch" },
        _vm._l(_vm.movies, function (movie, index) {
          return _c("MovieItem", {
            key: index,
            attrs: { value: _vm.movies[index] },
            scopedSlots: _vm._u(
              [
                {
                  key: "over",
                  fn: function () {
                    return [
                      _vm.isImpersonated
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "px-3 py-1 text-sm bg-base-yellow rounded absolute bottom-2 right-2 hidden group-hover:block",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addToCart(movie)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.consumers.movie_view.in_cart")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c(
                        "p",
                        { staticClass: "text-xl mt-3 break-words pb-8" },
                        [_vm._v(_vm._s(movie.film_name))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-base text-xs break-words absolute bottom-0",
                        },
                        [_vm._v(_vm._s(movie.director))]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "addedToCart",
          title: "カートに追加しました",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlInputWrapper", {
    attrs: {
      label: _vm.label,
      sublabel: _vm.sublabel,
      rules: _vm.rules,
      name: _vm.name,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mutableValue,
                  expression: "mutableValue",
                },
              ],
              ref: "textarea",
              staticClass:
                "w-full p-2 bg-white border rounded min-h-10 focus:outline-none",
              class: [
                errors.length
                  ? "bg-red-200 border-red-600"
                  : "border-brown-grey",
                _vm.disabled ? "bg-light-grey" : "",
              ],
              style: "height: " + _vm.height,
              attrs: {
                name: _vm.name,
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
              },
              domProps: { value: _vm.mutableValue },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.mutableValue = $event.target.value
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
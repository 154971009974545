var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "col-span-1 text-left flex relative hover:bg-very-light-grey group",
      on: {
        click: function ($event) {
          return _vm.openMovieDetail(_vm.value.uuid)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _c(
            "FlMovieImage",
            { attrs: { value: _vm.movieImage } },
            [_vm._t("over")],
            2
          ),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }